@import "./reset.css";
@import "./node_modules/flickity/dist/flickity.min.css";

:root {
  --color-black: #333333;
  --color-grey: #999999;
  --color-red: #9F403C;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html, body {
  color: var(--color-black);
}

.site-header {
  background: #fff;
  text-align: center;
  padding: 32px 16px;
}

h1 {
  color: var(--color-red);
  letter-spacing: 6px;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: normal;
}

h1 span {
  display: block;
  font-size: 3em;
  line-height: 1.4;
  letter-spacing: 20px;
  color: var(--color-black);
  font-weight: 700;
}

@media (min-width: 768px) {
  h1 span {
    letter-spacing: 40px;
    font-size: 6em;
  }
}

#intro {
  position: relative;
  background-image: url('./images/holding-phone.jpeg');
  background-size: cover;
  background-position: center center;
  padding: 0 32px;
}

#intro:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0, 0.2);
  z-index: 1;
}

.intro-location,
.intro-brand {
  text-align: center;
  color: #ffffff;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  letter-spacing: 6px;
  padding: 32px;
}

#intro-lockup {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr auto 3fr 1fr auto;
  z-index: 2;
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}

@media (min-width: 768px) {
  #intro-lockup {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 450px 1fr;
    border: none;
  }
}

.lockup-primary {
  color: #ffffff;
  font-size: 2em;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.3;
}

@media (max-width: 767px) {
  .lockup-primary br {
    display: none;
  }
}

@media (min-width: 768px) {
  .lockup-primary {
    font-size: 4em;
    letter-spacing: 10px;
  }
}

.lockup-primary--1 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
  text-align: left;
}

@media (min-width: 768px) {
  .lockup-primary--1 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    text-align: left;
  }
}

.lockup-primary--2 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 5;
  text-align: right;
}

@media (min-width: 768px) {
  .lockup-primary--2 {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: right;
  }
}

.lockup-secondary {
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.9em;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .lockup-secondary {
    letter-spacing: 8px;
    padding-bottom: 32px;
    line-height: 1;
  }
}

.lockup-secondary--1 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

@media (min-width: 768px) {
  .lockup-secondary--1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 4;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    text-align: right;
    position: relative;
  }

  .lockup-secondary--1:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 400px;
    background-color: #ffffff;
    top: 20%;
    left: 85%;
  }
}

.lockup-secondary--2 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 6;
  position: relative;
  text-align: right;
}

@media (min-width: 768px) {
  .lockup-secondary--2 {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  .lockup-secondary--2:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 400px;
    background-color: #ffffff;
    top: 20%;
    left: 85%;
  }
}

#definitions {
  padding: 64px 32px;
  color: var(--color-black);
  max-width: 960px;
  margin: 0 auto;
}

#definitions h2 {
  text-transform: uppercase;
  font-size: 1.2em;
  letter-spacing: 2px;
  margin-bottom: 32px;
}

@media (min-width: 768px) {
  #definitions h2 {
    letter-spacing: 8px;
  }
}

.definition-type {
  color: var(--color-grey);
  font-style: italic;
  line-height: 1.5;
}

.definition-entry {
  margin-bottom: 24px;
  line-height: 1.5;
}

.definition-quote {
  color: var(--color-grey);
  font-style: italic;
}

.definition-synonyms {
  font-style: italic;
  font-size: 0.9em;
}

.definition-hero {
  background-image: url('./images/group-hug.jpeg');
  background-size: cover;
  background-position: center center;
  position: relative;
  text-align: right;
  padding: 32px;
  border-top: 10px solid var(--color-red);
  border-bottom: 10px solid var(--color-red);
  height: 80vw;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.definition-hero:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0, 0.2);
  z-index: 1;
}

.definition-hero h3 {
  text-transform: uppercase;
  letter-spacing: 6px;
  text-align: right;
  position: relative;
  color: #ffffff;
  z-index: 2;
  display: inline-block;
  font-weight: bold;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .definition-hero h3 {
    max-width: 15ch;
    font-size: 3em;
    letter-spacing: 16px;
  }
}

#details {
  padding: 32px 16px 64px;
}

#details h2 {
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 4px;
  margin-bottom: 32px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  #details h2 {
    font-size: 3em;
    text-align: center;
    letter-spacing: 8px;
  }
}

#details .item-slider {
  list-style: none;
  counter-reset: details-counter;
}

#details .item-slide {
  list-style: none;
  counter-increment: details-counter;
}

#details .item-slide::before {
  content: counter(details-counter) ". ";
  color: var(--color-red);
  display: inline-block;
  font-weight: bold;
  font-size: 2em;
}

#details .item-slide {
  width: 90%;
  margin-right: 5%;
  padding: 16px;
  margin-bottom: 16px;
}

@media (min-width: 640px) {
  #details .item-slide {
    width: 45%;
    margin-right: 2.5%;
  }
}

@media (min-width: 960px) {
  #details .item-slide {
    width: 28%;
    margin-right: 2%;
  }
}

@media (min-width: 1280px) {
  #details .item-slide {
    width: 20%;
    margin-right: 2%;
  }
}

#details h4 {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 4px;
  font-size: 1.5em;
  margin-bottom: 16px;
}

#details .item-slide p,
#details .item-slide ul {
  font-size: 0.9em;
}

#details .item-slide p {
  line-height: 1.15;
}

#details .item-slide ul {
  margin-left: 16px;
}

#details .item-slide li {
  margin-bottom: 8px;
}

#details .item-slide p:not(:last-child),
#details .item-slide ul:not(:last-child) {
  margin-bottom: 16px;
}

#details .flickity-prev-next-button {
  top: calc(100% + 22px);
}

#details .flickity-prev-next-button.previous {
  left: 0;
}

#details .flickity-prev-next-button.next {
  right: 0;
}

.next-steps-banner h2 {
  text-align: left;
  padding: 24vw 16px 2vw;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #ffffff;
  background-image: url('./images/skating.jpeg');
  background-size: cover;
  background-position: center bottom;
}

@media (min-width: 768px) {
  .next-steps-banner h2 {
    font-size: 5em;
    letter-spacing: 8px;
  }
}

#next-steps p {
  font-size: 1.3em;
  text-align: center;
  padding: 32px 16px 0;
  line-height: 1.3;
}

.cta-list {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0 16px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
}

.cta-item:first-child {
  margin-bottom: 16px;
}

@media (min-width: 640px) {
  .cta-list {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .cta-item:first-child {
    margin-bottom: 0;
    margin-right: 32px;
  }
}


.cta {
  background: var(--color-grey);
  display: block;
  border-top: 5px solid var(--color-red);
  color: var(--color-black);
  text-decoration: none;
  padding: 16px 32px;
  font-weight: 700;
  transition: color 200ms ease, background-color 200ms ease;
}

.cta:hover {
  color: #ffffff;
  background-color: gray;
}

footer ul {
  list-style: none;
  max-width: 320px;
  margin: 16px auto;
  font-size: 2em;
  display: flex;
  justify-content: space-around;
}

footer a {
  color: var(--color-black);
  transition: color 200ms ease;
}

footer a:hover {
  color: var(--color-red);
}